// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-arvostele-[id]-js": () => import("./../../../src/pages/arvostele/[id].js" /* webpackChunkName: "component---src-pages-arvostele-[id]-js" */),
  "component---src-pages-cxex-js": () => import("./../../../src/pages/cxex.js" /* webpackChunkName: "component---src-pages-cxex-js" */),
  "component---src-pages-ei-haukku-haavaa-tee-index-js": () => import("./../../../src/pages/ei-haukku-haavaa-tee/index.js" /* webpackChunkName: "component---src-pages-ei-haukku-haavaa-tee-index-js" */),
  "component---src-pages-ei-haukku-haavaa-tee-matkahuolto-kokemuksia-js": () => import("./../../../src/pages/ei-haukku-haavaa-tee/matkahuolto-kokemuksia.js" /* webpackChunkName: "component---src-pages-ei-haukku-haavaa-tee-matkahuolto-kokemuksia-js" */),
  "component---src-pages-in-[id]-js": () => import("./../../../src/pages/in/[id].js" /* webpackChunkName: "component---src-pages-in-[id]-js" */),
  "component---src-pages-in-[id]-yv-js": () => import("./../../../src/pages/in/[id]/yv.js" /* webpackChunkName: "component---src-pages-in-[id]-yv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matkahuolto-kokemuksia-js": () => import("./../../../src/pages/matkahuolto-kokemuksia.js" /* webpackChunkName: "component---src-pages-matkahuolto-kokemuksia-js" */),
  "component---src-pages-palaute-[id]-js": () => import("./../../../src/pages/palaute/[id].js" /* webpackChunkName: "component---src-pages-palaute-[id]-js" */),
  "component---src-pages-palaute-[id]-yv-js": () => import("./../../../src/pages/palaute/[id]/yv.js" /* webpackChunkName: "component---src-pages-palaute-[id]-yv-js" */),
  "component---src-pages-palautelomake-js": () => import("./../../../src/pages/palautelomake.js" /* webpackChunkName: "component---src-pages-palautelomake-js" */),
  "component---src-pages-verkkokaupat-js": () => import("./../../../src/pages/verkkokaupat.js" /* webpackChunkName: "component---src-pages-verkkokaupat-js" */)
}

