import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
     palette: {
       //type: 'dark',
       primary: {
         main: '#0D3C79',
         form: '#3C7FFC;',
       },
       text: {
         primary: '#000000',
       },
       secondary: {
         main: '#ffffff',
       },
       contrastThreshold: 3,
       background: {
         default: '#FAFAFA'
       },
     },
     typography: {
      fontFamily: [
          'Roboto',
          'sans-serif'
        ].join(','),
        fontSize: 12,
        h1: {
          fontSize: 60,
          fontWeight: 300,
        },
        h2: {
          fontSize: 60,
          fontWeight: 300,
          color: '#0D3C79'
        },
        h5:{
          fontSize: 24,
          fontWeight: 400,
          color: '#0D3C79'
        },
        h6: {
          fontSize: 20,
          fontWeight: 500,
        },
        body2: {
          fontSize: 14,
        },
        body1: {
          fontSize: 16,
        },
        caption: {
          fontSize: 12,
        },
        subtitle1: {
          fontSize: 16,
        }
      },
      props: {
        MuiCheckbox: {
          size: 'small',
          margin: 'dense',
        },
        MuiRadioGroup: {
          size: 'small',
          margin: 'dense',
        },
        MuiRadio: {
          size: 'small',
          margin: 'dense',
        },
        MuiButton: {
          /*size: 'small',*/
        },
        MuiFilledInput: {
          margin: 'dense',
        },
        MuiFormControl: {
          margin: 'dense',
        },
        MuiFormHelperText: {
          margin: 'dense',
        },
        MuiIconButton: {
          size: 'small',
        },
        MuiInputBase: {
          margin: 'dense',
        },
        MuiInputLabel: {
          margin: 'dense',
        },
        MuiListItem: {
          dense: true,
        },
        MuiOutlinedInput: {
          margin: 'dense',
        },
        MuiFab: {
          size: 'small',
        },
        MuiTable: {
          size: 'small',
        },
        MuiTextField: {
          margin: 'dense',
        },
        MuiToolbar: {
          variant: 'dense',
        },
      },
      overrides: {
        MuiFormControl: {
          marginDense: {
            // Adjust spacing to reach minimal touch target hitbox
            marginTop: 0,
            marginBottom: 10,
          },
        },
        MuiCheckbox: {
          root: {
            paddingTop: 3,
            marginTop: 0,
          },
        },
        MuiRadio: {
          root: {
            paddingTop: 3
          }
        },
        MuiButton: {
          root: {
            backgroundColor: '#3C7FFC;',
          },
          containedPrimary: {
            backgroundColor: '#3C7FFC;', 
          }
        },
        MuiFormControlLabel: {
          root: {
            alignItems: 'flex-start'
          }
        }
      },

});

export default theme;